var columns = [{
  title: '排序',
  dataIndex: 'sort',
  key: 'sort',
  width: '10%',
  scopedSlots: {
    customRender: 'sort'
  }
}, {
  title: '封面',
  dataIndex: 'imgurl',
  key: 'imgurl',
  width: '15%',
  scopedSlots: {
    customRender: 'imgurl'
  }
}, {
  title: '链接',
  dataIndex: 'linkurl',
  key: 'linkurl',
  width: '40%',
  scopedSlots: {
    customRender: 'linkurl'
  }
}, {
  title: '适配端口',
  dataIndex: 'platformtype',
  key: 'platformtype',
  width: '10%',
  scopedSlots: {
    customRender: 'platformtype'
  }
}, {
  title: '状态',
  dataIndex: 'effectstatus',
  key: 'effectstatus',
  width: '10%',
  scopedSlots: {
    customRender: 'effectstatus'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: '15%',
  scopedSlots: {
    customRender: 'action'
  }
}];
var configColumns = [{
  title: '类型',
  dataIndex: 'name',
  key: 'name',
  width: '33%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '状态',
  dataIndex: 'effectstatus',
  key: 'effectstatus',
  width: '33%',
  scopedSlots: {
    customRender: 'effectstatus'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  width: '34%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns, configColumns };