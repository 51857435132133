import "core-js/modules/es.string.fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form-model", {
    ref: "dcBannerForm",
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      model: _vm.item
    }
  }, [!_vm.editId ? _c("a-form-model-item", {
    attrs: {
      label: "数据类型",
      prop: "type",
      rules: {
        required: true,
        message: "请选择数据类型"
      }
    }
  }, [_c("a-select", {
    model: {
      value: _vm.item.type,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "type", $$v);
      },
      expression: "item.type"
    }
  }, [_vm._l(_vm.types, function (item) {
    return [!item.fixed ? _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.title) + _vm._s(item.id))]) : _vm._e()];
  })], 2)], 1) : _vm._e(), _c("a-form-model-item", {
    attrs: {
      label: "适配端口",
      prop: "platformtype",
      rules: {
        required: true,
        message: "请选择适配端口"
      }
    }
  }, [_c("a-radio-group", {
    attrs: {
      disabled: _vm.isDisabled,
      name: "radioGroup"
    },
    model: {
      value: _vm.item.platformtype,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "platformtype", $$v);
      },
      expression: "item.platformtype"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_c("span", [_vm._v("App")])]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_c("span", [_vm._v("Web")])])], 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "banner图片",
      prop: "imgurl",
      rules: {
        required: true,
        message: "请上传图片"
      }
    }
  }, [_c("UploadImg", {
    attrs: {
      disabled: _vm.isDisabled,
      listType: "picture-card",
      max: 1,
      imgProportion: _vm.imgProportion,
      memorySize: "3M",
      force: false
    },
    on: {
      previewImg: _vm.previewImg,
      change: function change($event) {
        return _vm.revalidateField("imgurl");
      }
    },
    model: {
      value: _vm.item.imgurl,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "imgurl", $$v);
      },
      expression: "item.imgurl"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "链接地址",
      prop: "linkurl"
    }
  }, [_c("a-input", {
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      disabled: _vm.isDisabled,
      maxlength: "50"
    },
    model: {
      value: _vm.item.linkurl,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "linkurl", $$v);
      },
      expression: "item.linkurl"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "状态",
      prop: "effectstatus"
    }
  }, [_c("a-radio-group", {
    attrs: {
      disabled: _vm.isDisabled,
      name: "radioGroup"
    },
    model: {
      value: _vm.item.effectstatus,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "effectstatus", $$v);
      },
      expression: "item.effectstatus"
    }
  }, [_c("a-radio", {
    attrs: {
      value: false
    }
  }, [_c("span", [_vm._v("未生效")])]), _c("a-radio", {
    attrs: {
      value: true
    }
  }, [_c("span", [_vm._v("已生效")])])], 1)], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.disabledLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.handleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };